export default {
  ORGANIZATIONS_LIST: "Liste des organisations",
  ADD_ORGANIZATION: "Ajouter une organisation",
  ORGANIZATION_ADDED: "Organisation ajoutée",
  OWNER: "Propriétaire",
  EDIT_ORGANIZATION: "Modifier une organisation",
  ORGANIZATION_UPDATED: "Organisation mise à jour",
  SELECT_A_PACKAGE: "Choisir un forfait",
  ADD_SUBSCRIPTION: "Ajouter un abonnement",
  SUBSCRIPTION_INVOICE_ADDED: "Facture abonnement ajoutée",
  AUTHORIZATIONS: "Autorisations",
  DELETE_THIS_ORGANIZATION: "Supprimer cette organisation ?",
  ORGANIZATION_DELETED: "Organisation supprimée",
  VIEW_ORGANIZATION: "Organisation",
  VALIDATE: "Valider",
  CERTIFIED_LETTER_CONTENT: "Contenu de la lettre certifiée",
  WEBSITE_CONTENT: "Contenu du site web",
  PUBLIC_NOTICE_CONTENT: "Contenu de l'avis public",
};
